$(function() {
  window.Geenie.PageViewProject = {
    send: function(
      project_id,
      project_code,
      project_name,
      image_url,
      is_coming_soon,
      is_finished,
      tag_ids = "",
    ) {
      var isNative = window.navigator.userAgent.toLowerCase().match(/makuake-ios$/i)
          || window.navigator.userAgent.toLowerCase().match(/makuake-android$/i);
      if (isNative) {
        return;
      }

      if (window.braze) {
        window.braze.logCustomEvent('pageview_project', {
          timestamp: new Date(),
          project_id: project_id,
          project_code: project_code,
          project_name: project_name,
          image_url: image_url,
          is_coming_soon: is_coming_soon,
          is_finished: is_finished,
          tag: tag_ids.split(",").map(tag_id => ({"id": tag_id})),
        });
      }
    }
  };
});
